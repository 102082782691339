import request from '@/utils/request'
const baseURL = '/baseapi'
const SuggestApi = {
  SuggestRecordList: '/admin/suggest/recordList',
  SetSuggestStatus: '/admin/suggest/setSuggestStatus'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function suggestRecordList (parameter) {
  return request({
    baseURL: baseURL,
    url: SuggestApi.SuggestRecordList,
    method: 'post',
    data: parameter
  })
}

export function setSuggestStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: SuggestApi.SetSuggestStatus,
    method: 'post',
    data: parameter
  })
}
