<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <a-back-top />
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <a-form-item label="建议标题">
                <a-input placeholder="请输入建议标题" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="建议人">
                <a-input placeholder="请输入建议人姓名" v-model="queryParam.realName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="地区">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="defaultRegion"
                  @change="changeRegion"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="选择小区">
                <a-select
                  v-model="queryParam.communityId"
                  placeholder="请选择小区"
                >
                  <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                  <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                    {{ item.communityName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="searchForm">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-card
        :bordered="false"
        title="用户建议">
        <a-spin :spinning="loading">
          <a-list size="large" :pagination="pagination">
            <a-list-item :key="index" v-for="(item, index) in data">
              <a-list-item-meta :description="item.detail">
                <a slot="title">
                  {{ item.title }}
                  <a-tag color="gold" v-if="item.status === 0">{{ item.statusName }}</a-tag>
                  <a-tag color="lime" v-else-if="item.status === 1">{{ item.statusName }}</a-tag>
                  <a-tag color="green" v-else-if="item.status === 2">{{ item.statusName }}</a-tag>
                  <a-tag color="cyan" v-else>{{ item.statusName }}</a-tag></a>
              </a-list-item-meta>
              <div slot="actions">
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item><a style="color: #1890ff;" @click="dealSuggest(item.keyId, 1)">已查阅</a></a-menu-item>
                    <a-menu-item><a style="color: #1890ff;" @click="dealSuggest(item.keyId, 2)">已回复</a></a-menu-item>
                    <a-menu-item><a style="color: #1890ff;" @click="dealSuggest(item.keyId, 3)">已归档</a></a-menu-item>
                  </a-menu>
                  <a-button type="primary" :loading="dealSuggestLoading">操作<a-icon type="down"/></a-button>
                </a-dropdown>
              </div>
              <div class="list-content">
                <div class="list-content-item">
                  <span>建议人</span>
                  <p v-if="item.headPic !== ''"><a-avatar slot="avatar" size="small" :src="item.headPic"/> {{ item.realName }}</p>
                  <p v-else><a-avatar slot="avatar" size="small">U</a-avatar> {{ item.realName }}</p>
                  <p>{{ item.telephone }}</p>
                </div>
                <div class="list-content-item">
                  <span>小区</span>
                  <p>{{ item.areaCodeName }}{{ item.communityName }}</p>
                </div>
                <div class="list-content-item">
                  <span>提交时间</span>
                  <p>{{ item.updateTime }}</p>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-spin>
      </a-card>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { regionList } from '@/api/region'
import { setSuggestStatus, suggestRecordList } from '@/api/suggest'
import { regionCommunity } from '@/api/community'

export default {
  name: 'SuggestList',
  components: {
    STable,
    Ellipsis,
    STree
  },
  data () {
    return {
      loading: false,
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        realName: '',
        telephone: '',
        title: '',
        provinceId: 0,
        communityId: 0,
        cityId: 0,
        areaId: 0,
        pageNo: 1,
        pageSize: 10
      },
      regionList: [],
      defaultRegion: [],
      data: [],
      pagination: {
        onChange: (page, pageSize) => this.onChange(page, pageSize),
        onShowSizeChange: (current, pageSize) => this.showSizeChange(current, pageSize),
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        pageSize: 10,
        total: 0,
        defaultCurrent: 1,
        current: 1
      },
      regionCommunity: [],
      dealSuggestLoading: false
    }
  },
  filters: {
  },
  created () {
    this.suggestRecordListApi()
    this.regionListApi()
  },
  methods: {
    suggestRecordListApi () {
      const _this = this
      _this.loading = true
      suggestRecordList(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.data = res.result.data
          _this.pagination.pageSize = res.result.pageSize
          _this.pagination.total = res.result.totalCount
          _this.pagination.current = res.result.pageNo
          _this.loading = false
          console.log('pagination', _this.pagination)
        }
      }).catch((err) => {
        _this.loading = false
        console.log(err)
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    searchForm () {
      this.queryParam.pageNo = 1
      this.suggestRecordListApi()
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.regionCommunity = []
      this.queryParam = {
        realName: '',
        telephone: '',
        title: '',
        provinceId: 0,
        communityId: 0,
        cityId: 0,
        areaId: 0
      }
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.provinceId = e[0] ? e[0] : 0
      this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaId = e[2] ? e[2] : 0
      this.regionCommunityApi(this.queryParam.areaId)
      this.queryParam.communityId = 0
    },
    dealSuggest (keyId, status) {
      const _this = this
      _this.dealSuggestLoading = true
      setSuggestStatus({ keyId: keyId, status: status }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.suggestRecordListApi()
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.dealSuggestLoading = false
      }).catch((err) => {
        console.log(err)
        _this.dealSuggestLoading = false
      })
    },
    onChange (page, pageSize) {
      this.queryParam.pageNo = page
      this.queryParam.pageSize = pageSize
      this.suggestRecordListApi()
    },
    regionCommunityApi (areaCode) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionCommunity = res.result.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    showSizeChange (current, pageSize) {
      this.pagination.current = 1
      this.pagination.defaultCurrent = 1
      this.pagination.pageSize = pageSize
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.suggestRecordListApi()
    }
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
